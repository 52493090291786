<script setup>
const {t} = useI18n()
const IS_SSR = useNuxtApp().ssrContext
const passwordRuleList = ref(null)

const props = defineProps({
  id: {
    type: String,
    default: 'password-input',
  },
  label: {
    type: String,
    default: '',
  },
  showRules: {
    type: Boolean,
    default: false,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  errors: {
    type: String,
    default: '',
  },
})

if (props.showRules) {
  const {data: passwordPolicies} = await useAsyncData('password-policy', () =>
    $fetch(nuApiUrl('account/password-policy', IS_SSR), {
      method: 'GET',
    }),
  )

  passwordRuleList.value = passwordPolicies.value?.data
}
const lengthRule = computed(() => {
  return `${t('page_change_password.must_be_between')} ${passwordRuleList.value?.min_length} ${t(
    'page_change_password.and',
  )} ${passwordRuleList.value?.max_length} ${t('page_change_password.characters')}`
})

const lowercaseRule = computed(() => {
  return `${t('page_change_password.must_have')} ${passwordRuleList.value?.min_lowercase_letters} ${t(
    'page_change_password.lower_case_letter',
  )}`
})

const uppercaseRule = computed(() => {
  return `${t('page_change_password.must_have')} ${passwordRuleList.value?.min_uppercase_letters} ${t(
    'page_change_password.uppercase_letter',
  )} `
})

const digitsRule = computed(() => {
  return `${t('page_change_password.must_have')} ${passwordRuleList.value?.min_digits} ${t(
    'page_change_password.number',
  )}`
})

const allowedSymbolRule = computed(() => {
  if (!passwordRuleList?.allowed_symbols?.length) return ''
  return `${t('page_change_password.password_rule_five')} ${passwordRuleList?.allowed_symbols?.join(',')}`
})

const requiredSymbolRule = computed(() => {
  if (!passwordRuleList?.required_symbols?.length) return ''
  return ` ${t('page_change_password.required_symbols')} ${passwordRuleList?.required_symbols?.join(',')}`
})
const passwordRules = computed(() => [
  lengthRule.value,
  lowercaseRule.value,
  uppercaseRule.value,
  digitsRule.value,
  allowedSymbolRule.value,
  requiredSymbolRule.value,
])

const emit = defineEmits(['showPassword'])
const password = defineModel()

const label = computed(() => {
  return props.label || t('comp_nu_password_input.password')
})

const error = ref('')
const showPassword = ref(false)

const passwordType = computed(() => {
  return showPassword.value ? 'text' : 'password'
})

const handleTogglePassword = () => {
  showPassword.value = !showPassword.value
  emit('showPassword', showPassword.value)
}
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-password.css';
</style>

<template>
  <div class="nu-password-input">
    <NuInput
      :error-message="errors"
      :label="label"
      :type="passwordType"
      :id="props.id"
      class="custom-input nu-password-input"
      v-model="password"
    >
      <template #icon>
        <font-awesome-icon
          :icon="['fas', 'eye']"
          id="toggle-password"
          class="icon"
          v-show="!showPassword"
          @click="handleTogglePassword"
        />
        <font-awesome-icon
          :icon="['fas', 'eye-slash']"
          id="toggle-password"
          class="icon"
          v-show="showPassword"
          @click="handleTogglePassword"
        />
      </template>
    </NuInput>
    <div
      class="nu-password-input-rules"
      v-if="props.showRules"
    >
      <ul
        class="mb-0"
        :key="index"
        v-for="(rule, index) in passwordRules"
      >
        <li
          class="change-password-help-text"
          v-if="rule"
        >
          {{ rule }}
        </li>
      </ul>
    </div>
  </div>
</template>
